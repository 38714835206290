var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"setupWrapper"},[(_vm.isLoading)?_c('AppLoader'):_vm._e(),_c('div',{staticStyle:{"display":"grid","grid-template-columns":"80px 1fr 80px","align-items":"start"}},[_vm._m(0),_c('StepNavigation',{staticStyle:{"margin-bottom":"50px","min-width":"175px"},attrs:{"steps":_vm.global_steps,"currentstep":1}}),_c('span')],1),_vm._m(1),_c('div',{staticClass:"family-bottom slide-in-bottom",style:([
      _vm.checkFamilyId
        ? {
            background: '#badc58',
            padding: '50px 40px',
            'border-radius': '20px',
          }
        : {},
    ])},[_c('div',{staticClass:"setupMain__boxes"},[(_vm.checkFamilyId)?_c('div',[_c('h3',{staticClass:"searchTitle familyChoosedText"},[_c('svg',{staticClass:"animated-check",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M4.1 12.7L9 17.6 20.3 6.3","fill":"none"}})])])]):_c('div',[_c('h3',{staticClass:"searchTitle",staticStyle:{"text-align":"center","margin-bottom":"15px","color":"var(--main)","font-weight":"600","font-size":"14px"}},[_vm._v(" Enter your family name ")]),_c('div',{staticClass:"familyInput"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"w-full h-full bg-transparent border-none pr-4",attrs:{"type":"text","placeholder":"e.g. Smith","disabled":_vm.done},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value},function($event){_vm.isTyping = true}]}}),(!_vm.validateButton)?_c('img',{staticClass:"w-6 h-6",attrs:{"src":require("../../assets/onboarding-check.svg"),"alt":"search"}}):_vm._e()]),(_vm.loading)?_c('div',{staticStyle:{"margin-top":"30px"}},[_c('div',{staticClass:"spinner"})]):_vm._e(),(
            _vm.alternatives && _vm.alternatives.length > 0 && _vm.validateButton
          )?_c('div',[_c('p',{staticClass:"alreadyUsed"},[_vm._v(" That username is already in use. Here’s some suggestions ")]),_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","margin-top":"25px","justify-content":"center"}},_vm._l((_vm.alternatives.slice(0, 5)),function(item,i){return _c('div',{key:i,staticClass:"familyList",on:{"click":function($event){return _vm.selectFamily(item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e()])])]),_c('div',{staticClass:"nextButtons"},[_c('button',{staticClass:"nextButtons__next",class:{ nextButtons__active: _vm.validateFamily },attrs:{"disabled":_vm.validateButton || _vm.checkFamilyId},on:{"click":_vm.validateNextStep}},[_vm._v(" Next step ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"justify-self-start"},[_c('img',{staticClass:"mx-auto",staticStyle:{"max-width":"50px","object-fit":"contain"},attrs:{"src":require("../../assets/images/logo-color.png"),"alt":"Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"30px 20px 0 20px","font-size":"13px"}},[_c('p',[_vm._v(" Welcome to "),_c('span',{staticClass:"font-semibold text-primary"},[_vm._v("Sonik Pocket")]),_vm._v("! ")]),_c('br'),_c('p',[_vm._v(" Ready to show your children the "),_c('span',{staticClass:"font-semibold text-primary"},[_vm._v("value of money")]),_vm._v("? ")]),_c('br'),_c('p',[_vm._v(" Think education, but with "),_c('span',{staticClass:"font-semibold text-primary"},[_vm._v("a fun twist")]),_vm._v("! Our "),_c('span',{staticClass:"font-semibold text-primary"},[_vm._v("interactive e-learning materials")]),_vm._v(" and "),_c('span',{staticClass:"font-semibold text-primary"},[_vm._v("gamified content")]),_vm._v(" ensures that your little ones will remain "),_c('span',{staticClass:"font-semibold text-primary"},[_vm._v("entertained for hours")]),_vm._v(". ")]),_c('br'),_c('span',{staticClass:"font-semibold text-primary"},[_vm._v("Ready to get started?")])])
}]

export { render, staticRenderFns }