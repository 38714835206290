<template>
  <div class="setupWrapper">
    <AppLoader v-if="isLoading" />
    <div
      style="
        display: grid;
        grid-template-columns: 80px 1fr 80px;
        align-items: start;
      "
    >
      <span class="justify-self-start">
        <img
          class="mx-auto"
          src="../../assets/images/logo-color.png"
          alt="Logo"
          style="
            max-width: 50px;
            object-fit: contain;
          "
      /></span>
      <StepNavigation
        :steps="global_steps"
        :currentstep="1"
        style="margin-bottom: 50px; min-width: 175px"
      >
      </StepNavigation>
      <span></span>
    </div>

    <div style="padding: 30px 20px 0 20px; font-size: 13px">
      <p>
        Welcome to <span class="font-semibold text-primary">Sonik Pocket</span>!
      </p>
      <br />
      <p>
        Ready to show your children the
        <span class="font-semibold text-primary">value of money</span>?
      </p>
      <br />
      <p>
        Think education, but with
        <span class="font-semibold text-primary">a fun twist</span>! Our
        <span class="font-semibold text-primary"
          >interactive e-learning materials</span
        >
        and
        <span class="font-semibold text-primary">gamified content</span> ensures
        that your little ones will remain
        <span class="font-semibold text-primary">entertained for hours</span>.
      </p>
      <br />
      <span class="font-semibold text-primary">Ready to get started?</span>
    </div>
    <div
      class="family-bottom slide-in-bottom"
      :style="[
        checkFamilyId
          ? {
              background: '#badc58',
              padding: '50px 40px',
              'border-radius': '20px',
            }
          : {},
      ]"
    >
      <div class="setupMain__boxes">
        <div v-if="checkFamilyId">
          <h3 class="searchTitle familyChoosedText">
            <svg class="animated-check" viewBox="0 0 24 24">
              <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
            </svg>
          </h3>
        </div>
        <div v-else>
          <h3
            class="searchTitle"
            style="
              text-align: center;
              margin-bottom: 15px;
              color: var(--main);
              font-weight: 600;
              font-size: 14px;
            "
          >
            Enter your family name
          </h3>

          <div class="familyInput">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="e.g. Smith"
              :disabled="done"
              @input="isTyping = true"
              class="w-full h-full bg-transparent border-none pr-4"
            />
            <img v-if="!validateButton" src="../../assets/onboarding-check.svg" class="w-6 h-6" alt="search" />
          </div>

          <div v-if="loading" style="margin-top: 30px">
            <div class="spinner"></div>
          </div>
          <div
            v-if="
              alternatives && alternatives.length > 0 && validateButton
            "
          >
            <p class="alreadyUsed">
              That username is already in use. Here’s some suggestions
            </p>

            <div
              style="
                display: flex;
                flex-wrap: wrap;
                margin-top: 25px;
                justify-content: center;
              "
            >
              <div
                v-for="(item, i) in alternatives.slice(0, 5)"
                :key="i"
                class="familyList"
                @click="selectFamily(item)"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nextButtons">
      <button
        :disabled="validateButton || checkFamilyId"
        class="nextButtons__next"
        :class="{ nextButtons__active: validateFamily }"
        @click="validateNextStep"
      >
        Next step
      </button>
    </div>
  </div>
</template>
<script>
  import StepNavigation from "../../components/wizard/StepNavigation";
  import AppLoader from "../../components/AppLoader";
  import { mapActions } from "vuex";
  import axios from "axios";
  import config from "../../config";
  import status from "../../utils/statusCode";
  import _ from "lodash";

  export default {
    components: {
      StepNavigation,
      AppLoader,
    },
    props: {
      next: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        done: false,
        isLoading: true,
        modal: false,
        blue: "",
        red: "",
        minError: "",
        activeChild: 0,
        buttonColor: "",
        alternatives: [],
        editButtonColor: "blue",
        loading: false,
        searchQuery: "",
        isTyping: false,
        ok: false,
        disabled: 0,
        global_steps: [
          {
            id: 1,
            title: "STEP 1",
            icon_class: "fa fa-map-marker",
          },
          {
            id: 2,
            title: "STEP 2",
            icon_class: "fa fa-folder-open",
          },
          {
            id: 3,
            title: "STEP 3",
            icon_class: "fa fa-paper-plane",
          },
        ],
      };
    },
    computed: {
      validateFamily() {
        if (this.ok) return true;
        else return false;
      },
      validateButton() {
        if (this.ok) return false;
        else return true;
      },
      checkFamilyId() {
        //   const famId = JSON.parse(localStorage.getItem("guardian_family_id"));
        if (JSON.parse(localStorage.getItem("guardian_family_id"))) {
          return true;
        } else {
          return false;
        }
      },
    },
    watch: {
      searchQuery: _.debounce(function () {
        this.isTyping = false;
      }, 1000),
      isTyping: function (value) {
        if (!value) {
          this.searchUser(this.searchQuery);
        }
      },
    },

    async created() {
      // localStorage.clear();
      if (this.ok || this.checkFamilyId) {
        // this.$router.replace("/guardian/setup/children");
        this.$router.replace("/guardian");
      }
      return await Promise.all([this.fetchUser()]).then(() => {
        this.isLoading = false;
        // console.log(this.$route.path == "/setup")
      });
    },
    methods: {
      ...mapActions(["fetchUser", "TOAST"]),
      selectFamily(value) {
        this.searchQuery = value;
        this.ok = true;
        //   this.alternatives = [];
      },
      clear() {
        localStorage.clear();
        location.replace("https://" + window.location.host + "/?logout=true");
      },
      setFamily() {
        let loginUserToken = localStorage.getItem("guardian_token");
        let loginUserId = localStorage.getItem("guardian_user_id");
        axios
          .post(config.API_HOST + `/save/family`, {
            userId: loginUserId,
            token: loginUserToken,
            family_name: this.searchQuery,
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              localStorage.setItem("guardian_family_id", response.data.data.id);
              this.done = true;
              this.alternatives = [];
              // console.log(response);
            } else if (status.invalidToken(statusCode)) {
              this.deleteStorage();
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            } else {
              this.TOAST(response.data.message);
            }
          })
          .catch((error) => {
            throw error;
          });
      },
      searchUser: function (searchQuery) {
        this.loading = true;
        let loginUserToken = localStorage.getItem("guardian_token");
        let loginUserId = localStorage.getItem("guardian_user_id");
        axios
          .post(
            config.API_HOST +
              `/suggestionFamily?userId=${loginUserId}&token=${loginUserToken}&family_name=` +
              searchQuery
          )
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              this.loading = false;
              // console.log(typeof response.data.data);
              this.alternatives = response.data.data;
              // console.log(response.data);
              if (
                response.data.data == null ||
                Object.keys(response.data.data).length != 0
              ) {
                this.ok = false;
                this.loading = false;
                this.disabled = 0;
              } else {
                this.loading = false;
                this.ok = true;
                this.disabled = 1;
              }
            } else if (status.invalidToken(statusCode)) {
              this.deleteStorage();
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          });
      },
      async validateNextStep() {
        if (this.ok || this.checkFamilyId) {
          await Promise.all([this.setFamily()]).then(() => {
            setTimeout(() => {
              // this.$router.replace("/guardian/setup/children");
              this.$router.replace("/guardian");
            }, 1500);
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .validateFamily {
    background: var(--main);
    border: none;
    width: 100%;
    color: white;
    padding: 15px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
  }

  .nextButtons {
    // position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    left: 0;
    margin-top: auto;

    &__active {
      background: var(--main) !important;
    }

    &__next {
      background: #e5e5e5;
      width: 100%;
      height: 55px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      border-radius: 100px;
      margin-bottom: 0;
      text-transform: uppercase;

      &:active {
        opacity: 0.8;
      }
    }
  }

  .family-bottom {
    margin: 0 auto;
    margin-top: 80px;
    width: 100%;
    margin-bottom: 100px;
  }

  .setupMain__title {
    width: 100%;
    margin-bottom: 20px;
    font-size: 25px;
    text-align: center;
  }

  .alreadyUsed {
    color: #ff4f79;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-top: 15px;
  }

  .setupMain__content {
    position: relative;
  }

  .appButton {
    width: auto;
    height: 100%;
    border-radius: 74px;
    color: white;
    min-width: auto;
    font-size: 14px;
  }

  .familyListUl {
    list-style: none;
    text-align: center;
    margin-top: 30px;
    font-weight: 700;
    color: #7a615a;
  }

  .familyList {
    font-size: 14px;
    color: var(--main);
    text-align: center;
    padding: 5px;
    border-radius: 50px;
    margin: 0 10px 10px 0;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }

  .familyList:last-child {
    border-right: none;
  }

  .familyInput {
    height: 60px;
    color: var(--main);
    border-radius: 74px;
    outline: none;
    padding: 5px 30px;
    font-size: 14px;
    width: 100%;
    background: transparent;
    color: #806157;
    display: flex;
    border: 1px solid #d9d9d9;
    align-items: center;
  }

  .familyInput::placeholder {
    color: #806157;
    font-style: italic;
  }

  .next__Button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
  }

  .familySubtitle {
    color: #7a615a;
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
  }

  .familyChoosedText {
    text-align: center;
    color: #b9ac65;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 200;

    img {
      width: 15px;
      margin-right: 10px;
    }
  }

  .searchTitle {
    font-weight: 200;
    color: #7a615a;
    font-size: 18px;
    letter-spacing: 0.5px;
    margin: 0 0 5px 0;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.7);
  }

  .wrapper {
    overflow: scroll;
    height: 65%;
    padding-top: 0 !important;
  }

  .spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #0ca4c1 94%, #0000) top/9px 9px
        no-repeat,
      conic-gradient(#0000 30%, #0ca4c1);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 9px),
      #000 0
    );
    animation: spinner-c7wet2 1s infinite linear;
    margin: 0 auto;
  }

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }

  .animated-check {
    height: 50px;
    width: 50px;
  }

  .animated-check path {
    fill: none;
    stroke: #ffffff;
    stroke-width: 4;
    stroke-dasharray: 23;
    stroke-dashoffset: 23;
    animation: draw 0.2s linear forwards;
    animation-delay: 0.5s;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }

  .setupWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>
