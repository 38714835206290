<template>
  <ol class="step-indicator">
    <li
      is="StepNavigationStep"
      v-for="(step, index) in steps"
      :key="index"
      :step="step"
      :currentstep="currentstep"
    />
  </ol>
</template>

<script>
import StepNavigationStep from "./StepNavigationStep";
export default {
  components: {
    StepNavigationStep
  },
  props: ['steps', 'currentstep'],
  data: function () {
    return {
      indicatorclass: false
    }
  },
};
</script>


