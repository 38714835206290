<template>
  <li :class="indicatorclass">
    <div class="step">
      <img
        v-if="indicatorclass.activeStep"
        src="../../assets/images/checkmark.svg"
        width="9"
        style="opacity: 0"
      >
      <img
        v-else-if="indicatorclass.complete"
        src="../../assets/images/checkmark.svg"
        width="9"
      >
      <img
        v-else
        src="../../assets/images/checkmark.svg"
        width="9"
        style="opacity: 0"
      >
    </div>
    <!-- <div class="caption hidden-xs hidden-sm">
      {{ step.title }}
    </div> -->
  </li>
</template>

<script>
export default {
  props: ["step", "currentstep"],
  computed: {
    indicatorclass: function () {
      return {
        activeStep: this.step.id == this.currentstep,
        complete: this.currentstep > this.step.id,
      };
    },
  },
};
</script>


